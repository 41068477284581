<template>
  <div>
    <v-container fluid class="pa-6">
      <v-row>
        <v-col md="12">
          <v-card>
            <v-card-title>
              <h2 class="mb-0">{{$t('operations')}}</h2>
            </v-card-title>
            <v-card-text>
              <DatatableV2 :displayTitle="false" :flat="true" :url="apiUrl" :datatableId="1" ref="operation"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import DatatableV2 from '@/components/DatatableV2'

  export default {
    name:"OperationsDetailOperation",
    components: { DatatableV2 },
    data() {
      return {
        apiUrl: "",
      }
    },
    created() {
      this.apiUrl = "/entity/" + this.$route.params.entity_id + "/getOperations"
    },
  }
</script>
